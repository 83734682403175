<template>
  <div class="bg-gray-900 h-screen w-full text-white flex ">
    <div class="container flex items-center mx-auto">
    <div class="flex flex-wrap w-full sm:w-1/3 mx-auto bg-gray-800 rounded shadow-xl p-10">
    <div class="mx-auto flex-shrink">
      <logo />  
    </div>
                <div
                  class="w-full text-center text-white header font-bold my-4 text-lg"
                >
                  
 2Way link found <svg class="inline-block w-6 h-6" id="emoji" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
  <g id="color">
    <polygon fill="#F1B31C" stroke="none" points="26.1808,20.1808 38.75,32.75 51.3192,45.3192 29.7894,54.2798 8.2597,63.2403 17.2202,41.7106"/>
    <polygon fill="#FCEA2B" stroke="none" points="40,35 25.6924,20.6692 16.3914,42.5394 7.0905,64.4095"/>
    <polygon fill="#EA5A47" stroke="none" points="15.2238,45.2849 26.2858,56.3469 20.7548,58.5984 12.9576,50.8012"/>
    <polygon fill="#D22F27" stroke="none" points="17.3429,55.2475 20.7548,58.5984 26.2858,56.3469 21.4849,51.546"/>
    <polygon fill="#EA5A47" stroke="none" points="20.8045,32.1625 27.5011,38.8591 39.2002,50.5583 32.9273,53.4217 24.7555,45.2498 18.1504,38.6447"/>
    <polygon fill="#D22F27" stroke="none" points="26.5369,47.0312 32.9273,53.4216 39.2003,50.5582 31.3616,42.7197"/>
    <ellipse cx="30.2951" cy="14.4579" rx="2" ry="1.9708" fill="#8967aa" stroke="none"/>
    <path fill="#ea5a47" stroke="none" d="M23,37"/>
    <ellipse cx="60.2951" cy="18.4579" rx="2" ry="1.9708" fill="#f1b31c" stroke="none"/>
    <ellipse cx="57.2951" cy="39.4579" rx="2" ry="1.9708" fill="#d22f27" stroke="none"/>
  </g>
  <g id="hair"/>
  <g id="skin"/>
  <g id="skin-shadow"/>
  <g id="line">
    <polyline fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" points="50.6626,45.6395 50.8308,45.8076 28.9606,55.1086 7.0904,64.4096 16.3914,42.5394 25.6923,20.6692"/>
    <polyline fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" points="25.801,20.7779 38.2616,33.2384 50.6626,45.6395"/>
    <line x1="25.6923" x2="25.801" y1="20.6692" y2="20.7779" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
    <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M46.4905,7.3671c0.2347,0.4487,0.4027,0.943,0.4897,1.473c0.451,2.7473-1.447,5.4141-4.2392,5.9565"/>
    <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M42.9327,14.7763c-0.5049,0.0384-1.0133,0.1573-1.509,0.364c-2.5697,1.0713-3.828,4.093-2.8105,6.7492"/>
    <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M61.7928,26.7168c-0.0987,0.4967-0.2778,0.987-0.5425,1.4544c-1.372,2.4225-4.5229,3.309-7.0378,1.98"/>
    <path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M54.3745,30.2558c-0.4173-0.2868-0.8878-0.513-1.4036-0.663c-2.6733-0.7775-5.5486,0.7867-6.4223,3.4936"/>
  </g>
</svg>
                 
                </div>

                <div v-if="sessionObj" class="flex-shrink mx-auto">
      <stripe-checkout
        ref="checkoutRef"
        :pk="publishableKey"
        :session-id="sessionObj.id"
      />
      <button @click="submit" class="hover:bg-gray-900 hover:text-primaryGreen bg-primaryGreen text-gray-900 header font-bold rounded p-4">Purchase 2way Link</button>
    </div>

    
    </div>
    </div>
  </div>
</template>

<script>
import Logo from "../components/logo";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { store } from "../store/index";
export default {
  data() {
    return {
      sessionObj: null,
      publishableKey:
        "pk_test_51IaY8vKEyaGjGRCoH2X5ksowWJtwBEU7M5DgoQQ62MBZLf8ONonqOpcqLaMemyauca46rv4c2hWZKi4UIerx9L8y001v3Jv1Mi",
      successURL: "http://localhost:8080/receipt",
      cancelURL: "http://localhost:8080/purchase",
    };
  },
  methods: {
    getLink() {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = {
        type: "requestQuantity",
        paymentLink: this.$route.params.id,
        successURL: this.successURL,
        cancelURL: this.cancelURL,
      };

      this.axios
        .post(
          "https://nbmatf13x2.execute-api.us-east-1.amazonaws.com/dev/buyers",
          body,
          config
        )
        .then((response) => {
          console.log("Response", response.data);
          const json = JSON.parse(response.data.body);
          this.sessionObj = json;
          store.commit("stripeSession", json);
        })
        .catch((error) => {
          console.log(error);
          this.errored = error;
        });
    },
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
  mounted() {
    store.commit("paymentLink", this.$route.params.id);
    this.getLink();
  },
  components: {
    StripeCheckout,
    Logo
  },
};
</script>
